export const FREELANCER_ONBOARDING_INDEX_ROUTE = "/freelancer-onboarding";
export const SKILL_ROUTE = `${FREELANCER_ONBOARDING_INDEX_ROUTE}/skill`;
export const EDUCATION_ROUTE = `${FREELANCER_ONBOARDING_INDEX_ROUTE}/education`;
export const EMPLOYMENT_ROUTE = `${FREELANCER_ONBOARDING_INDEX_ROUTE}/employment`;
export const PAYMENT_ROUTE = `${FREELANCER_ONBOARDING_INDEX_ROUTE}/payment`;
export const PERSONALDATA_ROUTE = `${FREELANCER_ONBOARDING_INDEX_ROUTE}/personal-data`;
export const PROFILE_PREVIEW_ROUTE = `${FREELANCER_ONBOARDING_INDEX_ROUTE}/profile-preview`;
export const JOBS_ROUTE = `${FREELANCER_ONBOARDING_INDEX_ROUTE}/jobs`;
export const TARIFF_ROUTE = `${FREELANCER_ONBOARDING_INDEX_ROUTE}/tariff`;
export const PHOTO_ROUTE = `${FREELANCER_ONBOARDING_INDEX_ROUTE}/photo`;
export const PROFESSIONAL_SUMMARY = `${FREELANCER_ONBOARDING_INDEX_ROUTE}/professional-summary`;
