export const CONTENT_MANAGEMENT_INDEX_ROUTE = "/content-management";

export const PROJECTS_INDEX_ROUTE = `${CONTENT_MANAGEMENT_INDEX_ROUTE}/projects`;
export const FIND_PROJECTS_ROUTE = `${PROJECTS_INDEX_ROUTE}/find-projects`;
export const MY_BIDS_HOME_ROUTE = `${PROJECTS_INDEX_ROUTE}/my-bids-home`;
export const MY_PROJECTS_HOME_ROUTE = `${PROJECTS_INDEX_ROUTE}/my-projects-home`;
export const SAVED_PROJECTS_ROUTE = `${CONTENT_MANAGEMENT_INDEX_ROUTE}/saved-projects`;

export const MY_BIDS_ROUTE = `${PROJECTS_INDEX_ROUTE}/my-bids`;
export const OPEN_BIDS_ROUTE = `${MY_BIDS_ROUTE}/open-bids`;
export const CLOSED_BIDS_ROUTE = `${MY_BIDS_ROUTE}/closed-bids`;
export const JOB_INVITATIONS_ROUTE = `${MY_BIDS_ROUTE}/job-invitations`;
export const OFFER_LETTERS_ROUTE = `${MY_BIDS_ROUTE}/offer-letters`;

export const SUBMIT_REQUIRED_DOCUMENTS_ROUTE = `${PROJECTS_INDEX_ROUTE}/submit-required-documents/:offerId`;

export const MY_PROJECTS_ROUTE = `${PROJECTS_INDEX_ROUTE}/my-projects`;
export const PREVIOUS_PROJECTS_ROUTE = `${MY_PROJECTS_ROUTE}/previous-projects`;
export const VIEW_PREVIOUS_PROJECT_ROUTE = `${PROJECTS_INDEX_ROUTE}/view-previous-project/:projectId`;
export const REQUEST_PAYMENT_ROUTE = `${PROJECTS_INDEX_ROUTE}/request-payment/:projectId`;
export const MILESTONE_REQUEST_PAYMENT_ROUTE = `${PROJECTS_INDEX_ROUTE}/milestone-request-payment`;
export const VIEW_PROJECT_MILESTONES_ROUTE = `${PROJECTS_INDEX_ROUTE}/view-milestones/:projectId`;

export const PROJECT_FILES_ROUTE = `${PROJECTS_INDEX_ROUTE}/project-files`;
export const FEED_BACK_ROUTE = `${PROJECTS_INDEX_ROUTE}/feed-backs`;
export const LEAVE_FEED_BACKS_ROUTE = `${PROJECTS_INDEX_ROUTE}/leave-feedbacks`;
export const MILESTONE_COMPLETION_ROUTE = `${PROJECTS_INDEX_ROUTE}/milestone-completion/:id`;
export const UPLOAD_PROOF_OF_COMPLETION_ROUTE = `${PROJECTS_INDEX_ROUTE}/upload-proof-completion/:milestoneId`;
export const PROJECT_ACTIVITY_LOG_ROUTE = `${PROJECTS_INDEX_ROUTE}/activity-log/:projectId`;

export const PROJECT_DETAILS_ROUTE = `${PROJECTS_INDEX_ROUTE}/project-details/:id`;
export const REPORT_INAPPROPRIATE_ROUTE = `${PROJECTS_INDEX_ROUTE}/report`;
export const BUY_IT_POINTS_ROUTE = `${PROJECTS_INDEX_ROUTE}/buy-it-points`;
export const MAKE_A_BID_ROUTE = `${PROJECTS_INDEX_ROUTE}/make-a-bid/:projectId`;
export const CHOOSE_PAYMENT_METHODS_ROUTE = `${PROJECTS_INDEX_ROUTE}/payment-methods`;

export const MAKE_AN_OFFER2_ROUTE = `${CONTENT_MANAGEMENT_INDEX_ROUTE}/projects/make-an-offer2/:id`;
export const MAKE_AN_OFFER_ROUTE = `${CONTENT_MANAGEMENT_INDEX_ROUTE}/projects/make-an-offer/:id`;
export const VIEW_PROJECT_DETAILS_ROUTE = `${CONTENT_MANAGEMENT_INDEX_ROUTE}/projects/all-projects-details/:id`;

// export const MAKE_A_BID_ROUTE = `${CONTENT_MANAGEMENT_INDEX_ROUTE}/projects/make-a-bid/:id`;

// export const YOUR_BIDS_ROUTE = `${CONTENT_MANAGEMENT_INDEX_ROUTE}/your-bids`;
export const VIEW_ACTIVE_BID_ROUTE = `${PROJECTS_INDEX_ROUTE}/view-active-bid/:bidId`;
export const VIEW_OPEN_BIDS_ROUTE = `${PROJECTS_INDEX_ROUTE}/open-bids/:bidId`;
export const VIEW_CLOSED_BIDS_ROUTE = `${PROJECTS_INDEX_ROUTE}/view-closed-bid/:bidId`;

export const VIEW_JOB_INVITATION_DETAILS_ROUTE = `${PROJECTS_INDEX_ROUTE}/view-job-invitation/:jobInvitationId`;
export const SEND_QUERY_MESSAGE_ROUTE = `${PROJECTS_INDEX_ROUTE}/query-message/:projectId`;
export const DECLINE_INVITATION_ROUTE = `${PROJECTS_INDEX_ROUTE}/decline-invitation/:jobInvitationId`;

export const CONTRACT_DETAILS_ROUTE = `${PROJECTS_INDEX_ROUTE}/contract-details/:projectId`;

export const YOUR_WORKS_ROUTE = `${CONTENT_MANAGEMENT_INDEX_ROUTE}/your-works`;

export const YOUR_OFFERS_ROUTE = `${CONTENT_MANAGEMENT_INDEX_ROUTE}/your-offers`;
export const VIEW_AN_OFFER_ROUTE = `${PROJECTS_INDEX_ROUTE}/offers/:offerId`;
export const ACCEPT_OFFER_ROUTE = `${PROJECTS_INDEX_ROUTE}/accept-offer/offer/:offerId`;

export const PAYMENT_HISTORY_ROUTE = `${PROJECTS_INDEX_ROUTE}/payment-history/:projectId`;

export const MODIFICATION_REQUEST_ROUTE = `${PROJECTS_INDEX_ROUTE}/modification-request/project/:projectId`;
export const MODIFICATION_REQUEST_DETAILS_ROUTE = `${PROJECTS_INDEX_ROUTE}/modification-request/details/:projectId`;
export const WITHDRAW_MODIFICATION_REQUEST = `${PROJECTS_INDEX_ROUTE}/modification-request/withdraw/:projectId`;

export const WALLETS_INDEX_ROUTE = `${CONTENT_MANAGEMENT_INDEX_ROUTE}/wallets`;
export const METHODWITHDRAWAL_INDEX_ROUTE = `${WALLETS_INDEX_ROUTE}/method-withdrawal`;
export const INFOWITHDRAWAL_INDEX_ROUTE = `${WALLETS_INDEX_ROUTE}/info-withdrawal`;
export const BANK_WITHDRAWAL_INFO_INDEX_ROUTE = `${WALLETS_INDEX_ROUTE}/Bank-info`;
export const TRANSACTION_INFO_INDEX_ROUTE = `${WALLETS_INDEX_ROUTE}/Transaction-info/:id`;
export const TRANSINFOSTATE_INFO_INDEX_ROUTE = `${WALLETS_INDEX_ROUTE}/Trans-info-state/:id`;
export const AMOUNT_WITHDRAW_INDEX_ROUTE = `${WALLETS_INDEX_ROUTE}/Amount-withdraw`;

export const REPORTS_INDEX_ROUTE = `${CONTENT_MANAGEMENT_INDEX_ROUTE}/reports`;
export const PROGESSDETAILS_INDEX_ROUTE = `${REPORTS_INDEX_ROUTE}/progress-details`;
export const MESSAGES_INDEX_ROUTE = `${REPORTS_INDEX_ROUTE}/messages`;
export const TRANSACTION_INFO_PENDING_INDEX_ROUTE = `${REPORTS_INDEX_ROUTE}/Transaction-info-pending/:id`;
export const MYREPORTS_INDEX_ROUTE = `${REPORTS_INDEX_ROUTE}/my-reports`;
export const WEEKLYSUMMARY_INDEX_ROUTE = `${REPORTS_INDEX_ROUTE}/weekly-summary`;
export const WEEKLYREPORT_INDEX_ROUTE = `${REPORTS_INDEX_ROUTE}/weekly-report`;
export const UPGRADE_INDEX_ROUTE = `${REPORTS_INDEX_ROUTE}/my-report/up-grade`;
export const UPGRADEMEMBERSHIP_INDEX_ROUTE = `${REPORTS_INDEX_ROUTE}/my-report/up-grade-membership`;
export const MEMBERSHIPBILLING_INDEX_ROUTE = `${REPORTS_INDEX_ROUTE}/my-report/membership-billing`;
export const ITMEMBERSHIPPLAN_INDEX_ROUTE = `${REPORTS_INDEX_ROUTE}/my-report/IT-membership-plan`;
export const INVOICE_DETAIL_INDEX_ROUTE = `${REPORTS_INDEX_ROUTE}/invoice-detail/:id`;
export const TRANSACTION_HISTORY_INDEX_ROUTE = `${REPORTS_INDEX_ROUTE}/transaction-history`;
export const BUY_POINTS_INDEX_ROUTE = `${REPORTS_INDEX_ROUTE}/buy-points`;
export const BILLING_PAYMENT_INDEX_ROUTE = `${REPORTS_INDEX_ROUTE}/billing-payment`;
export const PAYMENT_CARD_INDEX_ROUTE = `${REPORTS_INDEX_ROUTE}/payment-card`;

export const INVOICES_INDEX_ROUTE = `${CONTENT_MANAGEMENT_INDEX_ROUTE}/invoices`;
export const MILESTONE_INVOICE_ROUTE = `${INVOICES_INDEX_ROUTE}/:projectId`;
export const INVOICE_DETAILS_ROUTE = `${CONTENT_MANAGEMENT_INDEX_ROUTE}/invoices/view-invoice/:invoiceId`;

export const NOTIFICATIONS_INDEX_ROUTE = `${CONTENT_MANAGEMENT_INDEX_ROUTE}/notifications`;

export const DISPUTE_INDEX_ROUTE = `${CONTENT_MANAGEMENT_INDEX_ROUTE}/disputes`;
export const DISPUTE_DETAILS_ROUTE = `${DISPUTE_INDEX_ROUTE}/dispute-details/:disputeId`;
export const RESOLUTION_REQUEST_ROUTE = `${DISPUTE_INDEX_ROUTE}/resolution-request`;
export const RAISE_DISPUTE_ROUTE = `${DISPUTE_INDEX_ROUTE}/raise-dispute/:projectId`;
export const PROJECT_DISPUTE_LOG = `${DISPUTE_INDEX_ROUTE}/project/:projectId`;
export const DISPUTE_INVOICE_ROUTE = `${DISPUTE_INDEX_ROUTE}/dispute-invoice/:projectId`;