import { Redirect, Route } from "react-router-dom";
import propTypes from "prop-types";
import { HOME_ROUTE } from "constants/urls/Home";
import { verifyAccessToken } from "utils/userManager";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const token = verifyAccessToken();

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: HOME_ROUTE,
              state: { from: location.pathname },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  location: propTypes.any,
  component: propTypes.any,
};
export default PrivateRoute;
