export const USERPROFILE_INDEX = "/user-profile-info";
export const USERPROFILE_SEARCH = `${USERPROFILE_INDEX}/user/:userId/service-provider/:serviceProviderId/project/:projectId`;
export const USERPROFILE_INVITE_ACCEPTED = `${USERPROFILE_INDEX}/invite/accepted`;
export const USERPROFILE_SHORTLISTED = `${USERPROFILE_INDEX}/user/:userId/service-provider/:serviceProviderId/shortlist/project/:projectId`;
export const USERPROFILE_INVITE_DECLINED = `${USERPROFILE_INDEX}/invite/declined`;
export const USERPROFILE_INVITE_PENDING = `${USERPROFILE_INDEX}/invite/pending`;
export const USERPROFILE_INVITE_WITHDRAWN = `${USERPROFILE_INDEX}/invite/withdrawn`;
export const USERPROFILE_BID_WITHDRAWN = `${USERPROFILE_INDEX}/bid/withdrawn`;
export const USERPROFILE_INVITITATION = `${USERPROFILE_INDEX}/user/:userId/service-provider/:serviceProviderId/project/:projectId/invitation/:invitationId/:invitationStatus`


// export const USERPROFILE_INVITE_ACCEPTED = `${USERPROFILE_INDEX}/invite?status=accepted`;
// export const USERPROFILE_SHORTLISTED = `${USERPROFILE_INDEX}/user?type=shortlisted`;
// export const USERPROFILE_INVITE_DECLINED = `${USERPROFILE_INDEX}/invite?type=declined`;
// export const USERPROFILE_INVITE_PENDING = `${USERPROFILE_INDEX}/invite?type=pending`;
// export const USERPROFILE_INVITE_WITHDRAWN = `${USERPROFILE_INDEX}/invite?type=withdrawn`;
// export const USERPROFILE_BID_WITHDRAWN = `${USERPROFILE_INDEX}/bid?type=withdrawn`;
