export const SERVICE_PROVIDER_ACCOUNT_SETTINGS_INDEX_ROUTE = "/accountsettings";

export const PRIVACY_AND_SECURITY_ROUTE = `${SERVICE_PROVIDER_ACCOUNT_SETTINGS_INDEX_ROUTE}/privacyAndSecurity`;
export const MEMBERSHIP_SETTING_ROUTE = `${SERVICE_PROVIDER_ACCOUNT_SETTINGS_INDEX_ROUTE}/membership-setting`;
export const MEMBERSHIP_PLAN_PAYMENT_INFO_ROUTE = `${MEMBERSHIP_SETTING_ROUTE}/plan-payment-info`;
export const DEVICE_SHARING_ROUTE = `${SERVICE_PROVIDER_ACCOUNT_SETTINGS_INDEX_ROUTE}/device-sharing`;
export const NOTIFICATION_ROUTE = `${SERVICE_PROVIDER_ACCOUNT_SETTINGS_INDEX_ROUTE}/notification`;
export const PAYMENT_AND_FINANCE_ROUTE = `${SERVICE_PROVIDER_ACCOUNT_SETTINGS_INDEX_ROUTE}/paymentAndFinance`;
export const EDIT_PAYMENT_SCHEDULE_ROUTE = `${SERVICE_PROVIDER_ACCOUNT_SETTINGS_INDEX_ROUTE}/edit/payment-schedule`;
export const ACCOUNT_ROUTE = `${SERVICE_PROVIDER_ACCOUNT_SETTINGS_INDEX_ROUTE}/account`;
export const BALANCE_SCORECARD_ROUTE = `${SERVICE_PROVIDER_ACCOUNT_SETTINGS_INDEX_ROUTE}/balance-scorecard`;
export const ACCOUNT_VERIFICATION_ROUTE = `${SERVICE_PROVIDER_ACCOUNT_SETTINGS_INDEX_ROUTE}/account-verification`;
export const WITHDRAWAL_ROUTE = `${SERVICE_PROVIDER_ACCOUNT_SETTINGS_INDEX_ROUTE}/withdrawal`;
export const EXCHANGE_ROUTE = `${SERVICE_PROVIDER_ACCOUNT_SETTINGS_INDEX_ROUTE}/exchange`;
export const PREVIEW_ACCOUNT_INFO_DETAILS_ROUTE = `${SERVICE_PROVIDER_ACCOUNT_SETTINGS_INDEX_ROUTE}/preview-account-info`;
