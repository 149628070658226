import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE =  {
	loading: false,
	user: { email: 'ekeneidiagbor@gmail.com', password: 'password'}
}

const authSlice = createSlice({
	name: 'auth',
	initialState: INITIAL_STATE,
	reducers: {
		login: (state, action) => {
			state.user = action.payload
		}
	}
})

export const { login } = authSlice.actions;
export default authSlice.reducer;
