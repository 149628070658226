import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  talentsList: [],
};

export const talentSlice = createSlice({
  name: "talentSlice",
  initialState: INITIAL_STATE,
  reducers: {
    loadTalents: (state, action) => {
      state.talentsList = action.payload;
    },
  },
});

export const { loadTalents } = talentSlice.actions;

export default talentSlice.reducer;
