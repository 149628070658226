import Loading from "components/loading/Loading";
import { lazy, Suspense } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import {
  HOME_ROUTE,
  HOW_IT_WORKS_ROUTE,
  ITSOLUTIONS_CONTACT_ROUTE,
  ENTERPRISE_RECRUITMENT_ROUTE,
  TERMS_AND_CONDITION_ROUTE,
  // PAGE_NOT_FOUND_ROUTE,
} from "constants/urls/Home";
import {
  LOGIN_ROUTE,
  SIGNUP_ROUTE,
  ACCOUNT_TYPE_ROUTE,
  SERVICE_PROVIDER_TYPE_ROUTE,
  WELCOME_SCREEN_ROUTE,
  RESET_PASSWORD_ROUTE,
  REQUEST_PASSWORD_RESET_OTP,
} from "constants/urls/Auth";
import { CONTENT_MANAGEMENT_INDEX_ROUTE } from "constants/urls/ContentManagement";
import { SUPPORT_INDEX_ROUTE } from "constants/urls/Support";
import { USERPROFILE_INDEX } from "constants/urls/UserprofileInfo";
import { CONGRATS_ROUTE, VERIFY_EMAIL_ROUTE } from "constants/urls/Congrats";
import { FREELANCER_ONBOARDING_INDEX_ROUTE } from "constants/urls/freelancerOnboarding";
import { USER_PROFILE_PREVIEW_INDEX_ROUTE } from "constants/urls/userProfilePreview";
import { SERVICE_PROVIDER_ACCOUNT_SETTINGS_INDEX_ROUTE } from "constants/urls/AccountSettings";
import { CLIENT_ACCOUNT_SETTING_INDEX_ROUTE } from "constants/urls/ClientContentMgt";
import { MESSAGES_ROUTE } from "constants/urls/Message";
import { CLIENT_ONBOARDING_INDEX_ROUTE } from "constants/urls/ClientOnboarding";
import { CLIENT_ONBOARDING_PREVIEW } from "constants/urls/ClientOnboardingPreview";
import { SIGNIN_OIDC, SIGNOUT_OIDC } from "constants/urls/Oidc";
import { CLIENT_CONTENT_MANAGEMENT_INDEX_ROUTE } from "constants/urls/ClientContentMgt";
import { USER_FEEDBACK_ROUTE } from "../constants/urls/UserFeedback";
import { BLOGS_ROUTE } from "../constants/urls/Blog";

const Home = lazy(() => import("pages/HOME"));
const Login = lazy(() => import("pages/AUTH/login/Login"));
const SignUp = lazy(() => import("pages/AUTH/signup/SignUp"));
const ResetPassword = lazy(() =>
  import("pages/AUTH/resetPassword/ResetPassword")
);
const UserFeedback = lazy(() => import("pages/USER_FEEDBACK/UserFeedback"));

const HowItWorks = lazy(() => import("pages/HOW_IT_WORKS/HowITWorks"));
const ContentManagement = lazy(() =>
  import("layouts/CONTENT_MANAGEMENT/ContentManagement")
);

const ClientContentMgt = lazy(() =>
  import("layouts/CLIENT_ONBORDING_CONTENT_MANAGEMENT/ClientContentMgt")
);

const Support = lazy(() =>
  import("layouts/ITSolutions_HELP_CENTER/ITSolutionsHelpCenter")
);

const UserProfileInfo = lazy(() =>
  import("layouts/USERPROFILE_INFO/UserProfileInfo")
);

const Congrats = lazy(() => import("layouts/CONGRATS/Congrats"));
// const VerifyEmail = lazy(() => import("pages/AUTH/verifyEmail/VerifyEmail"));
const FreelancerOboarding = lazy(() =>
  import("layouts/FREELANCER_ONBOARDING/FreelancerOnboarding")
);
const ClientOnboarding = lazy(() =>
  import("layouts/CLIENT_ONBOARDING/ClientOnboarding")
);
const ClientOnboardingPreview = lazy(() =>
  import("layouts/CLIENT_ONBOARDING_PREVIEW/ClientOnboardingPreview")
);

const UserProfilePreview = lazy(() =>
  import("layouts/USER_PROFILE_PREVIEW/userProfilePreview")
);
const AccountSettings = lazy(() =>
  import("layouts/ACCOUNT_SETTINGS/AccountSettings")
);

const ClientAccountingSettings = lazy(() => import("pages/CLIENT_ACCOUNT_SETTINGS/IndexPage.js"))
const Messages = lazy(() => import("layouts/MESSAGES/Messages"));
const AccountType = lazy(() => import("pages/AUTH/accountType/AccountType"));

const WelcomeScreen = lazy(() =>
  import("pages/AUTH/welcomeScreen/WelcomeScreen")
);
const ServiceProviderType = lazy(() =>
  import("pages/AUTH/serviceProviderType/ServiceProviderType")
);

const SignInOidc = lazy(() => import("pages/AUTH/signin-oidc/SigninOidc"));

const SignOutOidc = lazy(() => import("pages/AUTH/signout-oidc/SignOutOidc"));

const VerifyEmail = lazy(() =>
  import("pages/CONGRATS/verifyEmail/VerifyEmail")
);

const ContactITsolutions = lazy(() =>
  import("pages/CONTACT_ITSOLUTIONS/ContactItsolutions")
);
const EnterpriseRecruitment = lazy(() =>
  import("pages/ENTERPRISE_RECRUITMENT/RecruitmentForm")
);

const TermsAndConditions = lazy(() =>
  import("pages/TERMS_AND_CONDITIONS/TermsConditons")
);

const PasswordResetOtp = lazy(() =>
  import("pages/AUTH/passwordResetOtp/PasswordResetOtp")
);
const Blogs = lazy(() => import("pages/BLOG/blogs/Blogs"));
const BlogDetails = lazy(() => import("pages/BLOG/blogDrtails/BlogDetails"));
// const PageNotFound = lazy(() => import("pages/PAGE_NOT_FOUND/PageNotFound"));

const GlobalRoutes = () => {
  const location = useLocation();
  return (
    <>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path={HOME_ROUTE} component={Home} />
          <Route exact path={LOGIN_ROUTE} component={Login} />
          <Route path={RESET_PASSWORD_ROUTE} component={ResetPassword} />
          <Route
            path={REQUEST_PASSWORD_RESET_OTP}
            component={PasswordResetOtp}
          />
          <Route
            path={TERMS_AND_CONDITION_ROUTE}
            component={TermsAndConditions}
          />
          <Route exact path={SIGNUP_ROUTE} component={SignUp} />
          <Route exact path={USER_FEEDBACK_ROUTE} component={UserFeedback} />

          <Route path={HOW_IT_WORKS_ROUTE} component={HowItWorks} />
          <Route path={SUPPORT_INDEX_ROUTE} component={Support} />

          <Route
            path={ITSOLUTIONS_CONTACT_ROUTE}
            component={ContactITsolutions}
          />
          <Route
            path={ENTERPRISE_RECRUITMENT_ROUTE}
            component={EnterpriseRecruitment}
          />
          <Route path={ACCOUNT_TYPE_ROUTE} component={AccountType} />

          <Route
            path={SERVICE_PROVIDER_TYPE_ROUTE}
            component={ServiceProviderType}
          />

          <Route path={SIGNIN_OIDC} component={SignInOidc} />

          <Route path={SIGNOUT_OIDC} component={SignOutOidc} />
          <Route
            path={WELCOME_SCREEN_ROUTE}
            location={location}
            component={WelcomeScreen}
          />
          <Route
            path={VERIFY_EMAIL_ROUTE}
            location={location}
            component={VerifyEmail}
          />
          <Route
            path={`${BLOGS_ROUTE}/:slug`}
            location={location}
            component={BlogDetails}
          />
          <Route
            path={`${BLOGS_ROUTE}`}
            location={location}
            component={Blogs}
          />

          <PrivateRoute
            path={CONTENT_MANAGEMENT_INDEX_ROUTE}
            component={ContentManagement}
            location={location}
          />
          <PrivateRoute
            path={CLIENT_CONTENT_MANAGEMENT_INDEX_ROUTE}
            component={ClientContentMgt}
            // isAuthenticated={isAuthenticated}
            location={location}
          />

          <PrivateRoute
            path={USERPROFILE_INDEX}
            component={UserProfileInfo}
            location={location}
          />

          <PrivateRoute
            path={CONGRATS_ROUTE}
            component={Congrats}
            location={location}
          />
          <PrivateRoute
            path={FREELANCER_ONBOARDING_INDEX_ROUTE}
            component={FreelancerOboarding}
            location={location}
          />
          <PrivateRoute
            path={CLIENT_ONBOARDING_INDEX_ROUTE}
            component={ClientOnboarding}
            location={location}
          />
          <PrivateRoute
            path={CLIENT_ONBOARDING_PREVIEW}
            component={ClientOnboardingPreview}
            location={location}
          />
          <PrivateRoute
            path={USER_PROFILE_PREVIEW_INDEX_ROUTE}
            component={UserProfilePreview}
            location={location}
          />
          <PrivateRoute
            path={SERVICE_PROVIDER_ACCOUNT_SETTINGS_INDEX_ROUTE}
            component={AccountSettings}
            location={location}
          />
          <PrivateRoute path={CLIENT_ACCOUNT_SETTING_INDEX_ROUTE} component={ClientAccountingSettings} location={location} />
          <PrivateRoute
            path={MESSAGES_ROUTE}
            component={Messages}
            location={location}
          />
        </Switch>
      </Suspense>
    </>
  );
};

export default GlobalRoutes;
