import { BrowserRouter, Route } from "react-router-dom";
import GlobalRoutes from "navigations/GlobalRoutes";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title> IT solutions</title>
        <meta
          name="description"
          content="Hire verified service providers for your projects. Want to start delivering quality service and making money while at it? Signup on ITsolutions as a service provider(freelancer) today."
        />
        <meta
          name="keywords"
          content="IT Solutions, Tech Talents, Freelancing, Freelancers, Freelancer, Outsourcing, Service Providers, Tech Service Providers, African Tech Talent, Global Tech Talent, Software Development, Mobile Development, Remote work, Product Design, Business analysis, Graphics design, Content Development"
        />
      </Helmet>
      <BrowserRouter>
        <Route component={GlobalRoutes} />
      </BrowserRouter>
    </div>
  );
}

export default App;
