import { combineReducers } from "redux";
import authReducer from "pages/AUTH/redux/authReducer";
import FreelancerEduReducer from "pages/FREELANCER_ONBOARDING/education/redux/FreelancerEduReducer";
import talentSlice from "../reducers/common/talent/talentSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  freelancerEdu: FreelancerEduReducer,
  talent: talentSlice,
});

export default rootReducer;
