import axios from "axios";
import { getAccessToken } from "utils/userManager";

const Axios = axios.create({
  baseURL:
    process.env.REACT_APP_NODE_ENV === "development" ||
    process.env.REACT_APP_NODE_ENV === "staging"
      ? "https://it-solutions.cnnxl.com/api/v1"
      : "https://itsolutions.africa/api/v1",
});

Axios.interceptors.request.use(
  (config) => {
    // console.log(config);
    const accessToken = getAccessToken();
    config.headers = {
      authorization: `Bearer ${accessToken}`,
      accept: "application/json",
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) =>
    new Promise((resolve) => {
      resolve(response);
    }),

  async (error) => {
    return Promise.reject(error);
  }
);

export default Axios;
