import { TERMS_AND_CONDITION_ROUTE } from "./Home";

export const SUPPORT_INDEX_ROUTE = "/help-center";

/**************************************************************************************************
 * service provider help center article routes
 ***********************************************************************************************/

export const SERVICE_PROVIDER_HELP_CENTER_ROUTE = `${SUPPORT_INDEX_ROUTE}/service-providers`;
export const SERVICE_PROVIDER_HELP_CENTER_DOC_ROUTE = `${SERVICE_PROVIDER_HELP_CENTER_ROUTE}/docs`;

//general
export const SERVICE_PROVIDER_HELP_CENTER_GET_STARTED_DOC_ROUTE = `${SERVICE_PROVIDER_HELP_CENTER_DOC_ROUTE}/getting-started`;
export const SERVICE_PROVIDER_HELP_CENTER_BASIC_TROBULESHOOTING_DOC_ROUTE = `${SERVICE_PROVIDER_HELP_CENTER_DOC_ROUTE}/basic-troubleshooting`;
export const SERVICE_PROVIDER_HELP_CENTER_GENERAL_TIPS_DOC_ROUTE = `${SERVICE_PROVIDER_HELP_CENTER_DOC_ROUTE}/tips`;

//profile
export const SERVICE_PROVIDER_HELP_CENTER_PROFILE_PHOTO_DOC_ROUTE = `${SERVICE_PROVIDER_HELP_CENTER_DOC_ROUTE}/profile/photo-guide`;
export const SERVICE_PROVIDER_HELP_CENTER_PROFILE_DETAILS_EDIT_DOC_ROUTE = `${SERVICE_PROVIDER_HELP_CENTER_DOC_ROUTE}/profile/details/edit`;
export const SERVICE_PROVIDER_HELP_CENTER_PROFILE_PORTFOLIO_EDIT_DOC_ROUTE = `${SERVICE_PROVIDER_HELP_CENTER_DOC_ROUTE}/profile/portfolio/edit`;

//payment
export const SERVICE_PROVIDER_HELP_CENTER_MILESTONE_PAYMENT_DOC_ROUTE = `${SERVICE_PROVIDER_HELP_CENTER_DOC_ROUTE}/payment/service/milestone`;
export const SERVICE_PROVIDER_HELP_CENTER_DISPUTE_RESOLUTION_DOC_ROUTE = `${SERVICE_PROVIDER_HELP_CENTER_DOC_ROUTE}/payment/service/dispute`;

/**************************************************************************************************
 * client help center article routes
 ***********************************************************************************************/
export const CLIENT_HELP_CENTER_ROUTE = `${SUPPORT_INDEX_ROUTE}/clients`;
export const CLIENT_HELP_CENTER_DOC_ROUTE = `${CLIENT_HELP_CENTER_ROUTE}/docs`;
//general
export const CLIENT_HELP_CENTER_GET_STARTED_DOC_ROUTE = `${CLIENT_HELP_CENTER_DOC_ROUTE}/getting-started`;
export const CLIENT_HELP_CENTER_BASIC_TROBULESHOOTING_DOC_ROUTE = `${CLIENT_HELP_CENTER_DOC_ROUTE}/basic-troubleshooting`;

//project
export const CLIENT_HELP_CENTER_POST_PROJECT_DOC_ROUTE = `${CLIENT_HELP_CENTER_DOC_ROUTE}/post-a-project`;
export const CLIENT_HELP_CENTER_POST_PROJECT_TIPS_DOC_ROUTE = `${CLIENT_HELP_CENTER_DOC_ROUTE}/tips/post-a-project`;
export const CLIENT_HELP_CENTER_CHOSE_RIGHT_SP_DOC_ROUTE = `${CLIENT_HELP_CENTER_DOC_ROUTE}/choose-service-provider`;

//payment
export const CLIENT_HELP_CENTER_MILESTONE_PAYMENT_DOC_ROUTE = `${CLIENT_HELP_CENTER_DOC_ROUTE}/payment/service/milestone`;

export const CLIENT_HELP_CENTER_DISPUTE_RESOLUTION_DOC_ROUTE = `${CLIENT_HELP_CENTER_DOC_ROUTE}/payment/service/dispute`;

/**************************************************************************************************
 * terms and conditions article routes
 ***********************************************************************************************/
export const TERMS_AND_CONDITIONS_DOC_ROUTE = `${TERMS_AND_CONDITION_ROUTE}/terms`;
export const TERMS_AND_CONDITIONS_PRIVACY_DOC_ROUTE = `${TERMS_AND_CONDITION_ROUTE}/privacy`;
export const TERMS_AND_CONDITIONS_IPP_DOC_ROUTE = `${TERMS_AND_CONDITION_ROUTE}/ipp`;
export const TERMS_AND_CONDITIONS_INDEMNITY_DOC_ROUTE = `${TERMS_AND_CONDITION_ROUTE}/indemity`;
export const TERMS_AND_CONDITIONS_AGREEMENT_DOC_ROUTE = `${TERMS_AND_CONDITION_ROUTE}/agreement`;
