import { createSlice } from '@reduxjs/toolkit';
const INITIAL_STATE = {
  educationInfo: {},
  accomplishments: [],
};

// const FreelancerEduReducer = (state = initialState, action) => {
//   switch (action.type) {
//     default:
//       return state;
//   }
// };

export const FreelancerEduSlice = createSlice({ 
  name: 'freelancerEdu',
  initialState: INITIAL_STATE,
  reducers: {
    addEduInfo: (state, action) => {
      state.educationInfo = action.payload
    }
  }

})


export const { addEduInfo } = FreelancerEduSlice.actions;

export default FreelancerEduSlice.reducer;
