export const CLIENT_ONBOARDING_INDEX_ROUTE = "/client-onboarding";
export const TITLE_ROUTE = `${CLIENT_ONBOARDING_INDEX_ROUTE}/title`;
export const SKILLS_ROUTE = `${CLIENT_ONBOARDING_INDEX_ROUTE}/skills`;
export const BUDGET_ROUTE = `${CLIENT_ONBOARDING_INDEX_ROUTE}/budget`;
export const FIXED_BUDGET_ROUTE = `${CLIENT_ONBOARDING_INDEX_ROUTE}/fixed-budget`;
export const MILESTONE_BUDGET_ROUTE = `${CLIENT_ONBOARDING_INDEX_ROUTE}/milestone-budget`;
export const SCOPE_ROUTE = `${CLIENT_ONBOARDING_INDEX_ROUTE}/scope`;
export const FILES_ROUTE = `${CLIENT_ONBOARDING_INDEX_ROUTE}/files`;
export const INTERVIEWS_ROUTE = `${CLIENT_ONBOARDING_INDEX_ROUTE}/interviews`;
export const JOB_PREFERENCES_ROUTE = `${CLIENT_ONBOARDING_INDEX_ROUTE}/job-preferences`;
