// import { createStore, applyMiddleware } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers/rootReducer';


import { configureStore } from '@reduxjs/toolkit'

// import thunk from 'redux-thunk';

// const Store = createStore(
// 	rootReducer,
// 	composeWithDevTools(applyMiddleware(thunk))
// );

const store = configureStore({
	reducer: rootReducer
})

// loadUser(store, userManager);

export default store;
