import axios from "config/Axios";
import jwt from "jwt-decode";
import { CONTENT_MANAGEMENT_INDEX_ROUTE } from "constants/urls/ContentManagement";

export const getAccessToken = () => {
  let accessToken = localStorage.getItem("accessToken");
  accessToken = accessToken ? JSON.parse(accessToken) : null;

  return accessToken;
};

export const ApiFnGetUserData = async () => {
  try {
    const response = await axios.get(`/user`);
    return response.data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export function storeUserType(userType) {
  localStorage.setItem("userType", JSON.stringify(userType));
}

export const saveUserToStorage = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const getSignedInUserData = () => {
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  return user;
};

export const getFreelancerId = () => {
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  if (user) {
    const freelancerId = user.freelancerId;
    return freelancerId;
  }
};

export const getClientId = () => {
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  if (user) {
    const clientId = user.id;
    return clientId;
  }
};

export const getFreelancerInformation = async () => {
  const user = getSignedInUserData();
  const freelancerId = getFreelancerId();

  if (user && freelancerId) {
    const { data } = await axios.get(
      `/users/${user.id}/freelancer/${freelancerId}`
    );

    // console.log(data);
    return data.data;
  }
};

export const verifyAccessToken = () => {
  const token = getAccessToken();
  if (token) {
    let verifiedToken = jwt(token);
    const currentDate = new Date();

    if (verifiedToken.exp * 1000 < currentDate.getTime()) {
      verifiedToken = false;
    } else {
      verifiedToken = true;
    }
    // console.log(verifiedToken);
    return verifiedToken;
  }
};

export const getUserType = () => {
  let userType = localStorage.getItem("userType");
  userType = userType ? JSON.parse(userType) : null;
  return userType;
};

export const pushToContentManagement = (setup_step, history) => {
  if (setup_step === 0) {
    history.push(CONTENT_MANAGEMENT_INDEX_ROUTE);
  }
};

export const routeDeterminer = (history, freelancerRoute, clientRoute) => {
  const user = getSignedInUserData();

  if (user?.userType?.includes("Freelancer")) {
    history.push(freelancerRoute);
  }
  if (user?.userType?.includes("Client")) {
    history.push(clientRoute);
  }
};
