export const CLIENT_CONTENT_MANAGEMENT_INDEX_ROUTE =
  "/client/content-management";
export const CLIENT_PROJECTS_INDEX_ROUTE = `${CLIENT_CONTENT_MANAGEMENT_INDEX_ROUTE}/projects`;
export const CLIENT_INVOICES_INDEX_ROUTE = `${CLIENT_CONTENT_MANAGEMENT_INDEX_ROUTE}/invoices`;
export const CLIENT_ACCOUNT_SETTING_INDEX_ROUTE = `${CLIENT_CONTENT_MANAGEMENT_INDEX_ROUTE}/settings`;

//settings
export const CLIENT_PROFILE_SETTINGS_ROUTE = `${CLIENT_ACCOUNT_SETTING_INDEX_ROUTE}/profile`;
export const CLIENT_NOTIFICATION_SETTINGS_ROUTE = `${CLIENT_ACCOUNT_SETTING_INDEX_ROUTE}/notification`;
export const CLIENT_PRIVACY_SECURITY_SETTINGS_ROUTE = `${CLIENT_ACCOUNT_SETTING_INDEX_ROUTE}/privacy-and-security`;
export const CLIENT_DEVICE_SHARING_SETTINGS_ROUTE = `${CLIENT_ACCOUNT_SETTING_INDEX_ROUTE}/device-sharing`;


export const CLIENT_VIEW_MILESTONE_INVOICE_DETAILS_ROUTE = `${CLIENT_INVOICES_INDEX_ROUTE}/milestone/details/:invoiceId`;
export const CLIENT_GENERATED_INVOICE_RECIEPT_ROUTE = `${CLIENT_INVOICES_INDEX_ROUTE}/:invoiceId`;
export const CLIENT_TRANSACTIONS_INDEX_ROUTE = `${CLIENT_CONTENT_MANAGEMENT_INDEX_ROUTE}/transactions`;
export const CLIENT_TRANSACTIONRECEIPT_INDEX_ROUTE = `${CLIENT_CONTENT_MANAGEMENT_INDEX_ROUTE}/transactions/transaction-receipt/:id`;

//projects(Biddable)
export const CLIENT_POST_PROJECT_ROUTE = `${CLIENT_PROJECTS_INDEX_ROUTE}/post/new`;
export const CLIENT_BIDDABLE_PROJECTS_ROUTE = `${CLIENT_PROJECTS_INDEX_ROUTE}/onview`;
export const CLIENT_BIDDABLE_PROJECT_DETAILS_ROUTE = `${CLIENT_BIDDABLE_PROJECTS_ROUTE}/:id`;
export const CLIENT_OFFER_LETTER_ROUTE = `${CLIENT_PROJECTS_INDEX_ROUTE}/offer`;
export const CLIENT_OFFER_ACCEPTED_LETTER_ROUTE = `${CLIENT_PROJECTS_INDEX_ROUTE}/offer/accepted`;
export const CLIENT_OFFER_DECLINED_LETTER_ROUTE = `${CLIENT_PROJECTS_INDEX_ROUTE}/offer/declined`;
export const CLIENT_BIDDABLE_PROJECT_MILESTONESTRUCTURE_DETAILS_ROUTE = `${CLIENT_PROJECTS_INDEX_ROUTE}/milestone/:id/details`;

//Projects (disputes)
export const CLIENT_PROJECT_DISPUTES_ROUTE = `${CLIENT_PROJECTS_INDEX_ROUTE}/disputes`;
export const CLIENT_DISPUTE_RESOLUTION_REQUEST = `${CLIENT_PROJECTS_INDEX_ROUTE}/dispute/request`;
export const CLIENT_DETAILS_DISPUTE_RESOLUTION_REQUEST = `${CLIENT_DISPUTE_RESOLUTION_REQUEST}/:id`;
export const CLIENT_DETAILS_DISPUTE_RESOLUTION_LOG = `${CLIENT_PROJECT_DISPUTES_ROUTE}/log/:id`;
export const CLIENT_VIEW_ONGOING_PROJECT_DISPUTE = `${CLIENT_PROJECTS_INDEX_ROUTE}/dispute/now`;
export const CLIENT_RAISE_DISPUTE_FORM_ROUTE = `${CLIENT_VIEW_ONGOING_PROJECT_DISPUTE}/form`;

//Project Previous
export const CLIENT_PREVIOUS_PROJECT_ROUTE = `${CLIENT_PROJECTS_INDEX_ROUTE}/previous`;
export const CLIENT_SINGLE_PREVIOUS_PROJECT_DETAILS_ROUTE = `${CLIENT_PREVIOUS_PROJECT_ROUTE}/:id/details`;

//Projects(Ongoing)
export const CLIENT_ONGOING_PROJECTS_ROUTE = `${CLIENT_PROJECTS_INDEX_ROUTE}/ongoing`;
export const PROJECT_FILES_ROUTE = `${CLIENT_ONGOING_PROJECTS_ROUTE}/:id/files`;
export const PROJECT_ACTIVITY_LOG_ROUTE = `${CLIENT_ONGOING_PROJECTS_ROUTE}/activity/log`;
export const PROJECT_CONTRACT_DETAILS_ROUTE = `${CLIENT_ONGOING_PROJECTS_ROUTE}/:id/contract/details`;
export const PROJECT_OFFER_DETAILS_ROUTE = `${CLIENT_ONGOING_PROJECTS_ROUTE}/:id/offer/details`;
export const CLIENT_SINGLE_CURRENT_PROJECT_DETAILS_ROUTE = `${CLIENT_ONGOING_PROJECTS_ROUTE}/:id`;
export const CLIENT_CURRENT_PROJECT_PAYMENT_HISTORY_ROUTE = `${CLIENT_SINGLE_CURRENT_PROJECT_DETAILS_ROUTE}/payment-history`;
export const CLIENT_PAYMENT_CURRENT_MILESTONE_ROUTE = `${CLIENT_ONGOING_PROJECTS_ROUTE}/payment/current`;
export const CLIENT_CURRENT_PROJECT_PAYMENT_HISTORY_ADDENDUM_ROUTE = `${CLIENT_CURRENT_PROJECT_PAYMENT_HISTORY_ROUTE}/addendum/adjustment`;
export const CLIENT_CURRENT_PROJECT_MILESTONESTRUCTURE_DETAILS_ROUTE = `${CLIENT_ONGOING_PROJECTS_ROUTE}/milestone/:id/details`;
export const CLIENT_PROJECT_MILESTONE_MODIFICATION_REQUEST_ROUTE = `${CLIENT_ONGOING_PROJECTS_ROUTE}/milestone/:id/modifications`;
export const CLIENT_VIEW_MODIFICATION_REQUEST_DETAILS = `${CLIENT_ONGOING_PROJECTS_ROUTE}/details/modification/:requestId`;
export const CLIENT_MODIFICATION_REQUEST_DISAPPROVAL = `${CLIENT_ONGOING_PROJECTS_ROUTE}/modification/disapproval`;
export const CLIENT_MODIFICATION_REQUEST_APPROVAL = `${CLIENT_ONGOING_PROJECTS_ROUTE}/modification/approval`;
export const CLIENT_CURRENT_PROJECT_MILESTONESTRUCTURE_REVIEW = `${CLIENT_ONGOING_PROJECTS_ROUTE}/milestone/:id/review`;
export const CLIENT_CURRENT_PROJECT_SERVICE_PROVIDER_FEEDBACK_ROUTE = `${CLIENT_ONGOING_PROJECTS_ROUTE}/project/feedback/:id`;
export const CLIENT_CURRENT_PROJECT_RATE_SERVICE_PROVIDER_ROUTE = `${CLIENT_CURRENT_PROJECT_SERVICE_PROVIDER_FEEDBACK_ROUTE}/rate`;
export const CLIENT_VIEW_FEEDBACK_DETAILS_ROUTE = `${CLIENT_CURRENT_PROJECT_RATE_SERVICE_PROVIDER_ROUTE}/view/details`;
export const CLIENT_CURRENT_PROJECT_END_ROUTE = `${CLIENT_ONGOING_PROJECTS_ROUTE}/project/end`;

//Projects(Drafts)
export const CLIENT_PROJECT_DRAFTS_ROUTE = `${CLIENT_PROJECTS_INDEX_ROUTE}/drafts`;
export const CLIENT_PROJECT_SINGLE_DRAFT_DETAILS_ROUTE = `${CLIENT_PROJECT_DRAFTS_ROUTE}/:id`;
export const EDIT_PROJECT_ROUTE = `${CLIENT_PROJECTS_INDEX_ROUTE}/:id/edit`
